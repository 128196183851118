<template>
  <div class="myCourseDetail">
    <div class="pageMain">
      <div class="subTitle">
        <span>课程信息</span>
      </div>
      <div class="subImage">
        <img :src="CourseDetail.productPic" />
        <div class="CourseImfo">
          <span class="subName">{{ CourseDetail.productName }}</span>
          <span class="subCate"
            >课程分类：<span class="subColor">{{ CourseDetail.productCategoryName }}</span></span
          >
        </div>
      </div>
      <div class="subPrice subTitle">
        <span>课程定价</span>
      </div>
      <div class="subMoney">
        <span class="originalPrice ft">课程原价：</span>
        <span class="num">{{ CourseDetail.originalPrice }}</span>
      </div>
      <div class="subMoney">
        <span class="originalPrice fl">折扣后价格：</span>
        <span class="nums">{{ CourseDetail.retailPrice }}</span>
      </div>
      <div class="subMoney">
        <span class="originalPrice ft">批量按量：</span>
        <span class="nums">{{ CourseDetail.quantityPrice }}</span>
      </div>
      <div class="subMoney">
        <span class="originalPrice fe">按包年不限量：</span>
        <span class="nums">{{ CourseDetail.buyoutPrice }}</span>
      </div>
      <div class="subPrice subTitle">
        <span>课程介绍</span>
      </div>
      <div class="subHtml">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="introduce" v-html="CourseDetail.detailHtml"></div>
      </div>
    </div>
  </div>
</template>
<script>
import to from 'await-to'
import { myCourseDetail } from '@/api/teacher'
export default {
  data() {
    return {
      productId: '',
      CourseDetail: {},
    }
  },
  mounted() {
    this.productId = this.$route.params.productId
    this.handleGetmyCourseDetailData()
  },
  methods: {
    async handleGetmyCourseDetailData() {
      const [res, err] = await to(myCourseDetail({ productId: this.productId }))
      if (err) return this.$message.warning(err.msg)
      res.data.detailHtml = res.data.detailHtml.replace(
        /<img/g,
        "<img style='max-width: 100% !important;'",
      )
      this.CourseDetail = res.data
    },
  },
}
</script>
<style lang="scss" scoped>
.myCourseDetail {
  .pageMain {
    padding: 20px;
    background: #ffffff;
    .subTitle {
      background: #f6f6f6;
      border-radius: 2px;
      height: 42px;
      span {
        padding-left: 20px;
        font-size: 18px;
        font-weight: 500;
        line-height: 42px;
        color: #333333;
      }
    }
    .subImage {
      margin-top: 20px;
      display: flex;
      img {
        width: 282px;
        height: 145px;
        background: #333333;
      }
      .CourseImfo {
        margin-left: 20px;
        span {
          display: block;
        }
        .subName {
          margin-top: 40px;
          font-size: 24px;
          font-weight: 400;
          line-height: 24px;
          color: #333333;
        }
        .subCate {
          display: flex;
          margin-top: 30px;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          color: #606266;
          .subColor {
            color: #333333;
          }
        }
      }
    }
    .subPrice {
      margin-top: 40px;
    }
    .subMoney {
      padding-top: 16px;
      font-size: 14px;
      font-weight: 400;
      .ft {
        margin-left: 29px;
      }
      .fl {
        margin-left: 15px;
      }
      .fe {
        margin-left: 1px;
      }
      .originalPrice {
        color: #606266;
      }
      .num {
        color: #333333;
      }
      .nums {
        color: #ff7b33;
      }
    }
    .subHtml {
      margin-top: 19px;
      .introduce {
        width: 750px;
        padding: 20px;
        border: 1px solid #e2e2e2;
      }
    }
  }
}
</style>
